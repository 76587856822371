import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faHome,
  faComments,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import "../css/sidebar-main.css";

const SidebarMain = () => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    // Загрузка состояния из localStorage
    const savedState = localStorage.getItem("isCollapsed");
    return savedState === null ? false : JSON.parse(savedState);
  });

  useEffect(() => {
    // Сохранение состояния в localStorage при изменении
    localStorage.setItem("isCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
      </button>
      <nav className="sidebar-nav">
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active-link" : "")}
          end
        >
          <FontAwesomeIcon
            icon={faHome}
            style={{
              transform: "translate(5%, 0%)",
            }}
          />
          {!isCollapsed && <span>Главная</span>}
        </NavLink>
        <NavLink
          to="/chats"
          className={({ isActive }) => (isActive ? "active-link" : "")}
        >
          <FontAwesomeIcon icon={faComments} />
          {!isCollapsed && <span>Чаты</span>}
        </NavLink>
        <NavLink
          to="/settings"
          className={({ isActive }) => (isActive ? "active-link" : "")}
        >
          <FontAwesomeIcon
            icon={faCog}
            style={{
              transform: "translate(10%, 0%)",
            }}
          />
          {!isCollapsed && <span>Настройки</span>}
        </NavLink>
      </nav>
    </div>
  );
};

export default SidebarMain;
