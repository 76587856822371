import React, { createContext, useContext, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const SignalRContext = createContext();

export const SignalRProvider = ({ children }) => {
  const [userCount, setUserCount] = useState(0);
  const [lastMessage, setLastMessage] = useState(null);
  const [lastChat, setLastChat] = useState(null);
  const [connection, setConnection] = useState(null);
  const [error, setError] = useState(null);
  const [openChatId, setOpenChatId] = useState(null); // Добавлено состояние для хранения ID открытого чата

  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem("userId", userId);
    }

    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/gatewayhub?userId=${userId}`, {
        withCredentials: true,
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    newConnection
      .start()
      .then(() => {
        console.log("Connected to SignalR hub");

        newConnection.on("UpdateUserCount", (count) => {
          setUserCount(count);
        });

        newConnection.on("ReceiveMessage", async (messageId) => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/telegrambot/chat-message?id=${messageId}`
            );
            const message = response.data;
            if (message.chatId === openChatId) {
              setLastMessage(message);
            }
          } catch (error) {
            console.error("Failed to fetch message: ", error);
          }
        });

        newConnection.on("UpdateChat", async (chatId) => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/telegrambot/chat?id=${chatId}`
            );
            const chat = response.data;
            setLastChat(chat);
          } catch (error) {
            console.error("Failed to fetch chat: ", error);
          }
        });

        setConnection(newConnection);
      })
      .catch((e) => {
        console.log("Connection failed: ", e);
        setError(`Connection failed: ${e.message}`);
        console.error("Detailed error: ", e.message);
      });

    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, [openChatId]); // Добавлено openChatId в зависимости

  return (
    <SignalRContext.Provider value={{ userCount, lastMessage, lastChat, connection, error, setLastMessage, setOpenChatId, setLastChat }}>
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalR = () => useContext(SignalRContext);
