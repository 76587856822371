import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSignalR } from "./SignalRContext";
import "../css/chat-messages.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faClock, faExclamationTriangle, faSync } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

const ChatMessages = ({ chat, botInfo }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [pendingMessages, setPendingMessages] = useState([]);
    const { lastMessage, setLastMessage } = useSignalR();
    const messagesEndRef = useRef(null);
    const messagesListRef = useRef(null);
    const textareaRef = useRef(null);
    const [hasMoreOlderMessages, setHasMoreOlderMessages] = useState(true);
    const [hasMoreNewerMessages, setHasMoreNewerMessages] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [canHandleScroll, setCanHandleScroll] = useState(false);
    const firstUnreadMessageRef = useRef(null);
    const hasScrolledToUnread = useRef(false);

    const handleScroll = async (e) => {
        if (!canHandleScroll) return;

        const { scrollTop, scrollHeight, clientHeight } = e.target;

        if (scrollTop < 200 && hasMoreOlderMessages && !loading) {
            const previousScrollHeight = messagesListRef.current.scrollHeight;

            await loadMessages(true);

            const newScrollHeight = messagesListRef.current.scrollHeight;
            messagesListRef.current.scrollTop = newScrollHeight - previousScrollHeight;
        } else if (scrollHeight - scrollTop - clientHeight < 200 && hasMoreNewerMessages && !loading) {
            await loadMessages(false);
        }
    };

    const isUserAtBottom = () => {
        if (!messagesListRef.current) return false;
        return messagesListRef.current.scrollHeight - messagesListRef.current.scrollTop === messagesListRef.current.clientHeight;
    };

    const fetchMessages = async (isFirstLoad, loadOlderMessages, referenceMessageId = null) => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/telegrambot/chat-messages`, {
                    params: {
                        chatId: chat.id,
                        limit: 20,
                        isFirstLoad,
                        loadOlderMessages,
                        referenceMessageId
                    }
                }
            );

            if (response.data.length === 0) {
                if (loadOlderMessages) {
                    setHasMoreOlderMessages(false);
                } else {
                    setHasMoreNewerMessages(false);
                }
            } else {
                setMessages((prevMessages) => {
                    const newMessages = response.data.filter(
                        (msg) => !prevMessages.some((prevMsg) => prevMsg.id === msg.id)
                    );
                    return loadOlderMessages ? [...newMessages, ...prevMessages] : [...prevMessages, ...newMessages];
                });
            }
        } catch (error) {
            console.error("Failed to fetch messages:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadMessages = async (loadOlderMessages) => {
        const referenceMessageId = loadOlderMessages 
            ? messages.length > 0 && messages[0].id 
            : messages.length > 0 && messages[messages.length - 1].id;

        await fetchMessages(false, loadOlderMessages, referenceMessageId);
    };

    useEffect(() => {
        const initializeChat = async () => {
            await fetchMessages(true, false);
            setInitialLoad(false);
            setTimeout(() => {
                setCanHandleScroll(true);
            }, 500);
        };
        initializeChat();
    }, [chat.id]);

    useEffect(() => {
        if (firstUnreadMessageRef.current && !hasScrolledToUnread.current) {
            firstUnreadMessageRef.current.scrollIntoView({ behavior: "smooth" });
            hasScrolledToUnread.current = true;
        } else if (messagesEndRef.current && !firstUnreadMessageRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        if (lastMessage) {
            const shouldScroll = isUserAtBottom();
            if (lastMessage.chatId === chat.id) {
                setMessages((prevMessages) => {
                    const messageExists = prevMessages.some((msg) => msg.id === lastMessage.id);
                    if (messageExists) {
                        return prevMessages;
                    }
                    return [...prevMessages, lastMessage];
                });
                if (shouldScroll && messagesEndRef.current) {
                    setTimeout(() => {
                        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
                    }, 100);
                }
            }
            setLastMessage(null);
        }
    }, [lastMessage, chat.id, setLastMessage]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '40px';
            textareaRef.current.focus();
        }
    }, []);

    const handleSendMessage = async () => {
        if (newMessage.trim() === "") return;

        const tempId = Date.now();
        const tempMessage = {
            id: tempId,
            text: newMessage,
            chatId: chat.id,
            status: 'pending',
            createdDate: new Date()
        };

        const shouldScroll = isUserAtBottom();
        setPendingMessages([...pendingMessages, tempMessage]);
        setNewMessage("");

        if (textareaRef.current) {
            textareaRef.current.style.height = "40px";
        }

        if (shouldScroll && messagesEndRef.current) {
            setTimeout(() => {
                messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/telegrambot/send-message`,
                {
                    chatId: chat.id,
                    text: newMessage
                }
            );

            const message = response.data;
            setMessages((prevMessages) => [...prevMessages, message]);
            setPendingMessages((prevPendingMessages) => prevPendingMessages.filter((msg) => msg.id !== tempId));
            if (isUserAtBottom() && messagesEndRef.current) {
                setTimeout(() => {
                    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
                }, 100);
            }
        } catch (error) {
            console.error("Failed to send message: ", error);
            setPendingMessages((prevPendingMessages) =>
                prevPendingMessages.map((msg) =>
                    msg.id === tempId ? { ...msg, status: 'failed' } : msg
                )
            );
        }
    };

    const handleRetrySendMessage = async (message) => {
        setPendingMessages((prevPendingMessages) =>
            prevPendingMessages.map((msg) =>
                msg.id === message.id ? { ...msg, status: 'retrying' } : msg
            )
        );
        
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/telegrambot/send-message`,
                {
                    chatId: message.chatId,
                    text: message.text
                }
            );
    
            const newMessage = response.data;
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setPendingMessages((prevPendingMessages) => prevPendingMessages.filter((msg) => msg.id !== message.id));
        } catch (error) {
            console.error("Failed to send message: ", error);
            setPendingMessages((prevPendingMessages) =>
                prevPendingMessages.map((msg) =>
                    msg.id === message.id ? { ...msg, status: 'failed' } : msg
                )
            );
        }
    };    

    const handleInput = (e) => {
        setNewMessage(e.target.value);

        if (textareaRef.current) {
            textareaRef.current.style.height = "40px";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const markAsRead = async (messageId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/telegrambot/mark-as-read`,
                messageId,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.data.success) {
                console.error("Failed to mark message as read:", response.data.message);
            }
        } catch (error) {
            console.error("Failed to mark message as read: ", error);
        }
    };

    const markAsReadWithDelay = (messageId, delay) => {
        setTimeout(async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/telegrambot/mark-as-read`,
                    messageId,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.data.success) {
                    setMessages((prevMessages) =>
                        prevMessages.map((msg) =>
                            msg.id === messageId ? { ...msg, readIt: true } : msg
                        )
                    );
                } else {
                    console.error("Failed to mark message as read:", response.data.message);
                }
            } catch (error) {
                console.error("Failed to mark message as read: ", error);
            }
        }, delay);
    };

    const handleMessageVisibility = (message, ref) => {
        if (!message.readIt && message.senderId !== botInfo?.id) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            markAsReadWithDelay(message.id, 1000);
                        }, 0);
                        observer.disconnect();
                    }
                });
            }, { threshold: 1.0 });
            observer.observe(ref);
        }
    };

    return (
        <div className="chat-messages" ref={messagesListRef}>
            <div className="messages-list" onScroll={handleScroll}>
                {messages.map((msg, index) => (
                    <div
                        key={msg.id || `msg-${index}`}
                        className={`message-item ${msg.senderId === botInfo?.id ? "message-bot" : "message-user"} ${!msg.readIt && msg.senderId !== botInfo?.id ? "message-unread" : ""}`}
                        ref={(el) => {
                            if (el && !msg.readIt && msg.senderId !== botInfo?.id && !firstUnreadMessageRef.current) {
                                firstUnreadMessageRef.current = el;
                            }
                            if (el && !msg.readIt && msg.senderId !== botInfo?.id) {
                                handleMessageVisibility(msg, el);
                            }
                        }}
                    >
                        {msg.senderId === botInfo?.id ? (
                            <div className="message-user">
                                <img
                                    src={`data:image/jpeg;base64,${botInfo.photo}`}
                                    alt="Bot"
                                    className="message-photo"
                                />
                                <div className="message-info">
                                    <div className="message-name">{botInfo.name}</div>
                                    <div className="message-username">@{botInfo.username}</div>
                                </div>
                            </div>
                        ) : (
                            chat.user && (
                                <div className="message-user">
                                    <img
                                        src={`data:image/jpeg;base64,${chat.user.photo}`}
                                        alt="User"
                                        className="message-photo"
                                    />
                                    <div className="message-info">
                                        <div className="message-name">
                                            {chat.user.firstName} {chat.user.lastName}
                                        </div>
                                        <div className="message-username">@{chat.user.username}</div>
                                    </div>
                                </div>
                            )
                        )}
                        <div className="message-text">{msg.text}</div>
                        <div className="message-date">
                            {format(new Date(msg.createdDate), 'dd MMMM yyyy, HH:mm', { locale: ru })}
                        </div>
                    </div>
                ))}
                {pendingMessages.map((msg, index) => (
    <div key={msg.id || `pending-${index}`} className="message-item message-pending">
        <div className="message-text">{msg.text}</div>
        <div className="message-status">
            {msg.status === 'pending' && <FontAwesomeIcon icon={faClock} />}
            {msg.status === 'failed' && (
                <FontAwesomeIcon 
                    icon={faSync} 
                    className={`retry-icon ${msg.status === 'retrying' ? 'disabled' : ''}`} 
                    onClick={() => msg.status !== 'retrying' && handleRetrySendMessage(msg)} 
                />
            )}
            {msg.status === 'retrying' && <FontAwesomeIcon icon={faClock} className="retry-icon disabled" />}
        </div>
    </div>
))}
                <div ref={messagesEndRef} />
            </div>
            <div className="message-input-container">
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={handleInput}
                    onKeyPress={handleKeyPress}
                    placeholder="Место для сообщения..."
                    style={{ minHeight: '40px', maxHeight: '100px', overflowY: 'auto' }}
                />
                <button onClick={handleSendMessage} disabled={newMessage.trim() === ""}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </div>
    );
};

export default ChatMessages;
