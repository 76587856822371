import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, isAuthInitialized, userRoles } = useAuth();

  if (!isAuthInitialized) {
    return <div>Loading...</div>; // Или любой компонент, который вы хотите отображать во время инициализации
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && !userRoles.includes(requiredRole)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
