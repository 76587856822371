import React from "react";
import Header from "./Header";
import SidebarAdmin from "./SidebarAdmin";
import "../css/admin-home.css";

const AdminHome = () => {
  return (
    <div className="main-page">
      <Header />
      <div className="main-container">
        <SidebarAdmin />
        <div className="content">
          <h2>Добро пожаловать на главную страницу</h2>
          {/* Здесь будет основное содержимое страницы */}
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
