import React, { useState, useEffect } from "react";
import "../css/header.css";
import { useSignalR } from "./SignalRContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { Tooltip } from "react-tooltip";
import { useAuth } from "./AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

const Header = () => {
  const { userCount } = useSignalR();
  const { userRoles, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(location.pathname.startsWith("/admin"));

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (location.pathname.startsWith("/admin")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [location.pathname]);

  const handleToggle = () => {
    setIsAdmin((prevIsAdmin) => {
      const newIsAdmin = !prevIsAdmin;
      setTimeout(() => {
        if (newIsAdmin) {
          navigate("/admin");
        } else {
          navigate("/");
        }
      }, 300);
      return newIsAdmin;
    });
  };

  return (
    <header className="header">
      <div className="header-content">
        {userRoles.includes("Admin") && (
          <div
            data-tooltip-id="adminTooltip"
            data-tooltip-content={
              isAdmin
                ? "Перейти в обычный режим"
                : "Перейти в режим администратора"
            }
          >
            <label className="switch">
              <input type="checkbox" checked={isAdmin} onChange={handleToggle} />
              <span className="slider round">
                <FontAwesomeIcon icon={faTelegramPlane} className="telegram-icon" />
              </span>
            </label>
            <Tooltip id="adminTooltip" place="top" type="dark" effect="solid" />
          </div>
        )}
        {isAdmin && (
          <div className="admin-mode">
            <span>Режим администратора</span>
          </div>
        )}
        <div className="header-right">
          <div
            className="user-count"
            data-tooltip-id="userCountTooltip"
            data-tooltip-content={`Пользователей онлайн`}
          >
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faUser} className="user-icon" />
            </div>
            <span>{userCount}</span>
          </div>
          <Tooltip id="userCountTooltip" place="top" type="dark" effect="solid" />
          <div
            className="logout-wrapper"
            data-tooltip-id="logoutTooltip"
            data-tooltip-content={"Выйти на окно авторизации"}
          >
            <button onClick={logout} className="logout-button">
              <FontAwesomeIcon icon={faSignOutAlt} />
              {!isMobile && 'Выйти'}
            </button>
            <Tooltip id="logoutTooltip" place="top" type="dark" effect="solid" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
