import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAuth } from "./AuthContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { FaExclamationCircle } from "react-icons/fa";
import "../css/login.css";

const Login = () => {
  const { login } = useAuth();
  const [attempts, setAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const blockedUntil = localStorage.getItem("blockedUntilLogin");
    if (blockedUntil) {
      const timeLeft = Math.floor(
        (new Date(blockedUntil).getTime() - Date.now()) / 1000
      );
      if (timeLeft > 0) {
        setIsBlocked(true);
        setTimer(timeLeft);
      } else {
        localStorage.removeItem("blockedUntilLogin");
      }
    }
  }, []);

  useEffect(() => {
    if (isBlocked) {
      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsBlocked(false);
            setAttempts(0);
            localStorage.removeItem("blockedUntilLogin");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [isBlocked]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Введите имя пользователя"),
    password: Yup.string().required("Введите пароль"),
  });

  const onSubmit = async (values, { setSubmitting, setErrors, setStatus }) => {
    if (isBlocked) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        values
      );
      login(response.data.accessToken, response.data.refreshToken);
      setStatus({ serverError: null });
    } catch (error) {
      setAttempts((prev) => prev + 1);
      if (attempts >= 2) {
        const blockDuration = 60; // Блокируем на 60 секунд
        setIsBlocked(true);
        setTimer(blockDuration);
        const blockedUntil = new Date(Date.now() + blockDuration * 1000);
        localStorage.setItem("blockedUntilLogin", blockedUntil);
      }
      if (error.response && error.response.status === 401) {
        setStatus({ serverError: "Неверное имя пользователя или пароль" });
      } else {
        setStatus({ serverError: "Ошибка сервера. Попробуйте позже." });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>Авторизация</h1>
        {isBlocked && (
          <div className="block-message">
            Слишком много попыток входа. Повторите попытку через {timer} секунд.
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, errors, touched, status, dirty }) => (
            <Form className="login-form">
              {status && status.serverError && (
                <div className="server-error">{status.serverError}</div>
              )}
              <div className="form-group">
                <div className="input-wrapper">
                  <Field
                    type="text"
                    name="username"
                    placeholder="Имя пользователя"
                    autoComplete="username"
                    id="username"
                    className="input-field"
                  />
                  {errors.username && touched.username && (
                    <>
                      <FaExclamationCircle
                        data-tooltip-id="usernameTooltip"
                        data-tooltip-content={errors.username}
                        className="error-icon"
                      />
                      <Tooltip
                        id="usernameTooltip"
                        place="top"
                        type="dark"
                        effect="solid"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-wrapper">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Пароль"
                    id="password"
                    className="input-field"
                  />
                  {errors.password && touched.password && (
                    <>
                      <FaExclamationCircle
                        data-tooltip-id="passwordTooltip"
                        data-tooltip-content={errors.password}
                        className="error-icon"
                      />
                      <Tooltip
                        id="passwordTooltip"
                        place="top"
                        type="dark"
                        effect="solid"
                      />
                    </>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting || !isValid || !dirty || isBlocked}
              >
                Войти
              </button>
            </Form>
          )}
        </Formik>
        <p>
          Нет аккаунта? <Link to="/register">Зарегистрироваться</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
