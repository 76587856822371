import React, { useState } from "react";
import "../css/chat-list.css"; // Подключение нового CSS файла

const ChatList = ({ chats, onSelectChat }) => {
  const [selectedChatId, setSelectedChatId] = useState(null);

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
    onSelectChat(chatId);
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
  };

  return (
    <div className="chat-list">
      {chats.map((chat) => (
        <div
          key={chat.id}
          className={`chat-item ${chat.id === selectedChatId ? "selected" : ""}`}
          onClick={() => handleSelectChat(chat.id)}
        >
          {chat.user && chat.user.photo ? (
            <img
              src={`data:image/jpeg;base64,${chat.user.photo}`}
              alt="User"
              className="chat-photo"
            />
          ) : (
            <div className="chat-photo-placeholder">Фото отсутствует</div>
          )}
          <div className="chat-info">
            <div className="chat-name-container">
              <div className="chat-name">
                {chat.user ? `${chat.user.firstName} ${chat.user.lastName}` : "Неизвестный пользователь"}
              </div>
              <div className="chat-last-message-date">
                {chat.lastMessage ? formatDate(chat.lastMessage.createdDate) : ""}
              </div>
            </div>
            {chat.user && (
              <div className="chat-username">@{chat.user.username}</div>
            )}
            <div className="chat-last-message-container">
              <div className="chat-last-message">
                {chat.lastMessage ? chat.lastMessage.text : "Нет сообщений"}
              </div>
              {chat.unreadMessagesCount > 0 && (
                <div className="chat-unread-count">
                  {chat.unreadMessagesCount}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatList;
