import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import SidebarAdmin from "./SidebarAdmin";
import ChatList from "./ChatList";
import ChatMessages from "./ChatMessages";
import "../css/admin-chats.css";
import { useSignalR } from './SignalRContext';

const AdminChats = () => {
  const { setOpenChatId, lastChat, setLastChat } = useSignalR();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [botInfo, setBotInfo] = useState(null);

  useEffect(() => {
    const fetchChats = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/telegrambot/chats`
      );
      setChats(response.data);
    };

    const fetchBotInfo = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/telegrambot/bot`
      );
      setBotInfo(response.data);
    };

    fetchChats();
    fetchBotInfo();
  }, []);

  useEffect(() => {
    if (lastChat) {
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.id === lastChat.id ? lastChat : chat
        )
      );
      setLastChat(null);
    }
  }, [lastChat, setChats, setLastChat]);

  const handleSelectChat = (chatId) => {
    const chat = chats.find((c) => c.id === chatId);
    setSelectedChat(chat);
    setOpenChatId(chatId); // Устанавливаем идентификатор открытого чата
  };

  return (
    <div className="main-page">
      <Header />
      <div className="main-container">
        <SidebarAdmin />
        <div className="content">
          <div className="chat-container">
            <div className="chat-list-container">
              <ChatList chats={chats} onSelectChat={handleSelectChat} />
            </div>
            <div className="chat-messages-container">
              {selectedChat && <ChatMessages chat={selectedChat} botInfo={botInfo} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminChats;
