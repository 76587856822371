import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import MainHome from "./components/MainHome";
import Register from "./components/Register";
import AdminHome from "./components/AdminHome";
import { AuthProvider } from "./components/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import MainChats from "./components/MainChats";
import MainSettings from "./components/MainSettings";
import "./css/styles.css";
import "./App.css";
import AdminSettings from "./components/AdminSettings";
import AdminChats from "./components/AdminChats";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/chats" element={<MainChats />} />
        <Route path="/settings" element={<MainSettings />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute requiredRole="Admin">
              <AdminHome />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/chats"
          element={
            <ProtectedRoute requiredRole="Admin">
              <AdminChats />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/settings"
          element={
            <ProtectedRoute requiredRole="Admin">
              <AdminSettings />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<MainHome />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
