import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { FaExclamationCircle } from "react-icons/fa";
import "../css/register.css";
import axios from "axios";

const Register = () => {
  const [attempts, setAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [timer, setTimer] = useState(0);
  const [availabilityErrors, setAvailabilityErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState(
    new Array(6).fill("")
  );
  const [formValues, setFormValues] = useState({});
  const [verificationError, setVerificationError] = useState("");
  const [codeTimer, setCodeTimer] = useState(900); // 15 minutes timer
  const [verifyAttempts, setVerifyAttempts] = useState(0);
  const navigate = useNavigate();
  const inputs = useRef([]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setVerificationCode(new Array(6).fill(""));
    setVerificationError("");
    setVerifyAttempts(0);
    setAttempts((prev) => prev + 1);
    if (attempts >= 2) {
      const blockDuration = 60; // Блокируем на 60 секунд
      setIsBlocked(true);
      setTimer(blockDuration);
      const blockedUntil = new Date(Date.now() + blockDuration * 1000);
      localStorage.setItem("blockedUntil", blockedUntil);
    }
  }, [attempts]);

  useEffect(() => {
    const blockedUntil = localStorage.getItem("blockedUntil");
    if (blockedUntil) {
      const timeLeft = Math.floor(
        (new Date(blockedUntil).getTime() - Date.now()) / 1000
      );
      if (timeLeft > 0) {
        setIsBlocked(true);
        setTimer(timeLeft);
      } else {
        localStorage.removeItem("blockedUntil");
      }
    }
  }, []);

  useEffect(() => {
    if (isBlocked) {
      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsBlocked(false);
            setAttempts(0);
            localStorage.removeItem("blockedUntil");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [isBlocked]);

  useEffect(() => {
    if (isModalOpen) {
      const countdown = setInterval(() => {
        setCodeTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            closeModal();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [isModalOpen, closeModal]);

  const initialValues = {
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(3, "Имя пользователя должно содержать минимум 3 символа")
      .required("Введите имя пользователя"),
    password: Yup.string()
      .min(6, "Пароль должен содержать минимум 6 символов")
      .matches(/[a-z]/, "Пароль должен содержать хотя бы одну строчную букву")
      .matches(/[A-Z]/, "Пароль должен содержать хотя бы одну заглавную букву")
      .matches(/\d/, "Пароль должен содержать хотя бы одну цифру")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Пароль должен содержать хотя бы один специальный символ"
      )
      .required("Введите пароль"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Пароли должны совпадать")
      .required("Подтвердите пароль"),
    email: Yup.string()
      .email("Неправильный формат электронной почты")
      .required("Введите электронную почту"),
  });

  const checkAvailability = async (type, value) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/check-${type}?${type}=${value}`
      );
      if (response.data) {
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        return false;
      } else {
        throw new Error("Ошибка при проверке доступности");
      }
    }
  };

  const handleRegister = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    if (isBlocked) {
      return;
    }

    try {
      const emailAvailable = await checkAvailability("email", values.email);
      const usernameAvailable = await checkAvailability(
        "username",
        values.username
      );

      if (!emailAvailable) {
        setErrors((prev) => ({
          ...prev,
          email: "Электронная почта уже занята",
        }));
      }
      if (!usernameAvailable) {
        setErrors((prev) => ({
          ...prev,
          username: "Имя пользователя уже занято",
        }));
      }

      if (!emailAvailable || !usernameAvailable) {
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/send-verification-code`,
        {
          email: values.email,
        }
      );

      setFormValues(values);
      setIsModalOpen(true);
      setCodeTimer(900); // Reset timer to 15 minutes
    } catch (error) {
      setAttempts((prev) => prev + 1);
      if (attempts >= 2) {
        const blockDuration = 60; // Блокируем на 60 секунд
        setIsBlocked(true);
        setTimer(blockDuration);
        const blockedUntil = new Date(Date.now() + blockDuration * 1000);
        localStorage.setItem("blockedUntil", blockedUntil);
      }
      if (error.response && error.response.status === 409) {
        setStatus({ serverError: "Ошибка регистрации. Попробуйте снова." });
      } else {
        setStatus({ serverError: "Ошибка сервера. Попробуйте позже." });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleBlur = async (e, fieldName, setFieldTouched, setFieldError) => {
    const { value } = e.target;
    setFieldTouched(fieldName, true);
    if (validationSchema.fields[fieldName].isValidSync(value)) {
      const available = await checkAvailability(fieldName, value);
      if (!available) {
        const errorMessage =
          fieldName === "email"
            ? "Электронная почта уже занята"
            : "Имя пользователя уже занято";
        setFieldError(fieldName, errorMessage);
        setAvailabilityErrors((prev) => ({
          ...prev,
          [fieldName]: errorMessage,
        }));
      } else {
        setAvailabilityErrors((prev) => {
          const updatedErrors = { ...prev };
          delete updatedErrors[fieldName];
          return updatedErrors;
        });
      }
    }
  };

  const handleVerificationCodeChange = (e, index) => {
    const { value } = e.target;
    if (/^\d{0,1}$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
      if (value && index < 5) {
        inputs.current[index + 1].focus();
      }
      if (index === 5 && value) {
        handleVerifyCode(newCode.join(""));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && verificationCode[index] === "") {
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(paste)) {
      setVerificationCode(paste.split(""));
      handleVerifyCode(paste);
    }
  };

  const handleVerifyCode = async (code) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          username: formValues.username,
          password: formValues.password,
          confirmPassword: formValues.confirmPassword,
          email: formValues.email,
          verificationCode: code,
        }
      );
      if (response.data) {
        navigate("/login");
      }
    } catch (error) {
      console.error(
        "Error during verification:",
        error.response || error.message
      );
      setVerifyAttempts((prev) => prev + 1);
      if (verifyAttempts >= 2) {
        closeModal();
      } else {
        if (error.response && error.response.status === 400) {
          setVerificationError("Неверный код подтверждения");
        } else {
          console.error("Ошибка проверки кода:", error);
          alert("Ошибка проверки кода. Попробуйте снова.");
        }
        setVerificationCode(new Array(6).fill(""));
        inputs.current[0].focus();
      }
    }
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <h1>Регистрация</h1>
        {isBlocked && (
          <div className="block-message">
            Слишком много попыток регистрации. Повторите попытку через {timer}{" "}
            секунд.
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          {({
            isSubmitting,
            isValid,
            errors,
            touched,
            status,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => (
            <Form className="register-form">
              {status && status.serverError && (
                <div className="server-error">{status.serverError}</div>
              )}
              <div className="form-group">
                <div className="input-wrapper">
                  <Field
                    type="text"
                    name="username"
                    placeholder="Имя пользователя"
                    autoComplete="username"
                    id="username"
                    onBlur={(e) =>
                      handleBlur(e, "username", setFieldTouched, setFieldError)
                    }
                    className="input-field"
                  />
                  {(errors.username || availabilityErrors.username) &&
                    touched.username && (
                      <>
                        <FaExclamationCircle
                          data-tooltip-id="usernameTooltip"
                          data-tooltip-content={
                            errors.username || availabilityErrors.username
                          }
                          className="error-icon"
                        />
                        <Tooltip
                          id="usernameTooltip"
                          place="top"
                          type="dark"
                          effect="solid"
                        />
                      </>
                    )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-wrapper">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Пароль"
                    id="password"
                    className="input-field"
                  />
                  {errors.password && touched.password && (
                    <>
                      <FaExclamationCircle
                        data-tooltip-id="passwordTooltip"
                        data-tooltip-content={errors.password}
                        className="error-icon"
                      />
                      <Tooltip
                        id="passwordTooltip"
                        place="top"
                        type="dark"
                        effect="solid"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-wrapper">
                  <Field
                    type="password"
                    name="confirmPassword"
                    placeholder="Подтверждение пароля"
                    id="confirmPassword"
                    className="input-field"
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <>
                      <FaExclamationCircle
                        data-tooltip-id="confirmPasswordTooltip"
                        data-tooltip-content={errors.confirmPassword}
                        className="error-icon"
                      />
                      <Tooltip
                        id="confirmPasswordTooltip"
                        place="top"
                        type="dark"
                        effect="solid"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="input-wrapper">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Электронная почта"
                    autoComplete="email"
                    id="email"
                    onBlur={(e) =>
                      handleBlur(e, "email", setFieldTouched, setFieldError)
                    }
                    className="input-field"
                  />
                  {(errors.email || availabilityErrors.email) &&
                    touched.email && (
                      <>
                        <FaExclamationCircle
                          data-tooltip-id="emailTooltip"
                          data-tooltip-content={
                            errors.email || availabilityErrors.email
                          }
                          className="error-icon"
                        />
                        <Tooltip
                          id="emailTooltip"
                          place="top"
                          type="dark"
                          effect="solid"
                        />
                      </>
                    )}
                </div>
              </div>
              <button
                type="submit"
                disabled={
                  isSubmitting ||
                  !isValid ||
                  !dirty ||
                  isBlocked ||
                  Object.keys(availabilityErrors).length > 0
                }
              >
                Зарегистрироваться
              </button>
            </Form>
          )}
        </Formik>
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content" onPaste={handlePaste}>
              <h2 style={{ marginBottom: "20px" }}>Подтверждение кода с почты</h2>
              <div style={{ marginBottom: "20px" }}>
                Осталось времени: {Math.floor(codeTimer / 60)}:
                {codeTimer % 60 < 10 ? `0${codeTimer % 60}` : codeTimer % 60}
              </div>
              {verificationError && (
                <div className="error-message" style={{ color: "red" }}>
                  {verificationError}
                </div>
              )}
              <div
                className="modal-input-container"
                style={{ marginBottom: "20px" }}
              >
                {verificationCode.map((digit, idx) => (
                  <input
                    key={idx}
                    type="text"
                    value={digit}
                    onChange={(e) => handleVerificationCodeChange(e, idx)}
                    onKeyDown={(e) => handleKeyDown(e, idx)}
                    ref={(el) => (inputs.current[idx] = el)}
                    className="modal-input-field"
                    maxLength="1"
                  />
                ))}
              </div>
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={closeModal}
              >
                Закрыть
              </button>
            </div>
          </div>
        )}
        <p style={{ marginTop: "20px" }}>
          Уже есть аккаунт? <Link to="/login">Войти</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
