import React from "react";
import Header from "./Header";
import SidebarMain from "./SidebarMain";
import "../css/main-home.css";

const MainHome = () => {
  return (
    <div className="main-page">
      <Header />
      <div className="main-container">
        <SidebarMain />
        <div className="content">
          <h2>Добро пожаловать на главную страницу</h2>
          {/* Здесь будет основное содержимое страницы */}
        </div>
      </div>
    </div>
  );
};

export default MainHome;
