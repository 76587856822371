import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Убедитесь, что библиотека установлена
import axios from "axios";
import Cookies from "js-cookie";

const AuthContext = createContext();

const noAuthRequiredRoutes = ["/login", "/register"];

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = useCallback(() => {
    console.log("Logging out...");
    setIsAuthenticated(false);
    setUserData(null);
    setUserRoles([]);
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    navigate("/login");
    console.log("User logged out");
  }, [navigate]);

  const refreshToken = useCallback(async () => {
    try {
      console.log("Attempting to refresh token...");
      const currentRefreshToken = Cookies.get("refreshToken");
      if (!currentRefreshToken) {
        throw new Error("No refresh token available");
      }
      console.log("Refresh token:", currentRefreshToken);

      const decodedRefreshToken = jwtDecode(currentRefreshToken);
      const currentTime = Date.now() / 1000;
      if (decodedRefreshToken.exp < currentTime) {
        console.log("Refresh token expired, logging out");
        logout();
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`,
        { refreshToken: currentRefreshToken }
      );
      console.log("Response from refresh token API:", response.data);
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;

      const decodedAccessToken = jwtDecode(newAccessToken);
      const newAccessTokenExpiryDate = new Date(decodedAccessToken.exp * 1000);
      const newRefreshTokenExpiryDate = new Date(decodedRefreshToken.exp * 1000);

      Cookies.set("accessToken", newAccessToken, {
        expires: newAccessTokenExpiryDate,
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set("refreshToken", newRefreshToken, {
        expires: newRefreshTokenExpiryDate,
        secure: true,
        sameSite: "Strict",
      });

      setUserData(decodedAccessToken);
      setUserRoles(decodedAccessToken.role || []);
      setIsAuthenticated(true);
      console.log("Token refreshed successfully");

      // Устанавливаем таймер для обновления токена перед его истечением
      const timeUntilExpiry = (decodedAccessToken.exp - currentTime - 30) * 1000; // 30 seconds before expiry
      setTimeout(refreshToken, timeUntilExpiry);
    } catch (error) {
      console.error("Failed to refresh token", error);
      logout();
    }
  }, [logout]);

  const checkTokenExpiry = useCallback(async () => {
    console.log("Checking token expiry...");
    const accessToken = Cookies.get("accessToken");
    const currentRefreshToken = Cookies.get("refreshToken");
    console.log("Access token:", accessToken);
    console.log("Refresh token:", currentRefreshToken);

    const currentTime = Date.now() / 1000;

    if (currentRefreshToken) {
      try {
        const decodedRefreshToken = jwtDecode(currentRefreshToken);
        if (decodedRefreshToken.exp < currentTime) {
          console.log("Refresh token expired, logging out");
          logout();
          return;
        }
      } catch (error) {
        console.error("Failed to decode refresh token", error);
        logout();
        return;
      }
    }

    if (accessToken) {
      try {
        const decodedAccessToken = jwtDecode(accessToken);
        console.log("Decoded access token:", decodedAccessToken);
        if (decodedAccessToken.exp < currentTime) {
          console.log("Access token expired, refreshing...");
          await refreshToken();
        } else {
          setUserData(decodedAccessToken);
          setUserRoles(decodedAccessToken.role || []);
          setIsAuthenticated(true);
          console.log("Access token is valid, user authenticated");

          const timeUntilExpiry = (decodedAccessToken.exp - currentTime - 30) * 1000; // 30 seconds before expiry
          setTimeout(refreshToken, timeUntilExpiry);
        }
      } catch (error) {
        console.error("Failed to decode access token", error);
        logout();
      }
    } else if (currentRefreshToken) {
      console.log("No access token found but refresh token is present, refreshing...");
      await refreshToken();
    } else {
      console.log("No access token or refresh token found, logging out");
      logout();
    }
    setIsAuthInitialized(true);
  }, [refreshToken, logout]);

  useEffect(() => {
    if (!noAuthRequiredRoutes.includes(location.pathname)) {
      checkTokenExpiry();
    } else {
      setIsAuthInitialized(true);
    }
  }, [checkTokenExpiry, location.pathname]);

  const login = async (accessToken, refreshToken) => {
    try {
      console.log("Attempting to login...");
      const decodedAccessToken = jwtDecode(accessToken);
      const decodedRefreshToken = jwtDecode(refreshToken);

      console.log("Decoded access token:", decodedAccessToken);
      console.log("Decoded refresh token:", decodedRefreshToken);

      setUserData(decodedAccessToken);
      setUserRoles(decodedAccessToken.role || []);
      setIsAuthenticated(true);

      const accessTokenExpiryDate = new Date(decodedAccessToken.exp * 1000);
      const refreshTokenExpiryDate = new Date(decodedRefreshToken.exp * 1000);

      Cookies.set("accessToken", accessToken, {
        expires: accessTokenExpiryDate,
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set("refreshToken", refreshToken, {
        expires: refreshTokenExpiryDate,
        secure: true,
        sameSite: "Strict",
      });

      navigate("/");
      console.log("User logged in successfully");

      const timeUntilExpiry = (decodedAccessToken.exp - Date.now() / 1000 - 30) * 1000; // 30 seconds before expiry
      setTimeout(refreshToken, timeUntilExpiry);
    } catch (error) {
      console.error("Invalid token on login:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthInitialized,
        userData,
        userRoles,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
